import type { FC } from 'react'

import { mockHelpCats } from '~/mock'
import { THREAD } from '~/const/thread'
import useViewingCommunity from '~/hooks/useViewingCommunity'

import ArrowLinker from '~/widgets/ArrowLinker'

import CatSection from './CatSection'
import useSalon from '../salon/search_hint'

import type { TProps as TIndex } from '..'

type TProps = Pick<TIndex, 'sections'>

const SearchHint: FC<TProps> = ({ sections }) => {
  const s = useSalon()

  const community = useViewingCommunity()
  const cats = mockHelpCats()

  return (
    <div className={s.wrapper}>
      <div className={s.header}>
        <div className={s.title}>常见问题</div>
      </div>
      <div className={s.body}>
        {cats.map((item) => (
          <CatSection key={item.title} item={item} />
        ))}
      </div>

      <div className={s.footer}>
        更多类似问题，请移步
        <ArrowLinker href={`/${community.slug}/${THREAD.DOC}`} left={1}>
          帮助台
        </ArrowLinker>
      </div>
    </div>
  )
}

export default SearchHint
